.whatsapp-sticky {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 70px;
  height: 70px;
  z-index: 100;
  svg {
    width: 100%;
  }
}
